import "./Chat.css";

export default function Chat({ channelName }: { channelName: string }) {
  return (
    <iframe
      id="twitch-chat-embed"
      className="twitch-chat-embed"
      title="twitch-chat-embed"
      src={`https://www.twitch.tv/embed/${channelName}/chat?darkpopout&parent=${window.location.hostname}`}
    ></iframe>
  );
}
