export const PlayIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 163 163">
      <path
        fill="#fff"
        d="M34.857,3.613C20.084-4.861,8.107,2.081,8.107,19.106v125.637c0,17.042,11.977,23.975,26.75,15.509L144.67,97.275 c14.778-8.477,14.778-22.211,0-30.686L34.857,3.613z"
      ></path>
    </svg>
  );
};

export const PauseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 47">
      <path d="M17.991,40.976c0,3.662-2.969,6.631-6.631,6.631l0,0c-3.662,0-6.631-2.969-6.631-6.631V6.631C4.729,2.969,7.698,0,11.36,0 l0,0c3.662,0,6.631,2.969,6.631,6.631V40.976z"></path>
      <path d="M42.877,40.976c0,3.662-2.969,6.631-6.631,6.631l0,0c-3.662,0-6.631-2.969-6.631-6.631V6.631 C29.616,2.969,32.585,0,36.246,0l0,0c3.662,0,6.631,2.969,6.631,6.631V40.976z"></path>
    </svg>
  );
};

export const MutedIcon = () => {
  return (
    <svg viewBox="0 0 448 448" xmlSpace="preserve">
      <path d="M352.021,16.075c0-6.08-3.52-11.84-8.96-14.4c-5.76-2.88-12.16-1.92-16.96,1.92l-141.76,112.96l167.68,167.68V16.075z"></path>
      <path d="M443.349,420.747l-416-416c-6.24-6.24-16.384-6.24-22.624,0s-6.24,16.384,0,22.624l100.672,100.704h-9.376 c-9.92,0-18.56,4.48-24.32,11.52c-4.8,5.44-7.68,12.8-7.68,20.48v128c0,17.6,14.4,32,32,32h74.24l155.84,124.48 c2.88,2.24,6.4,3.52,9.92,3.52c2.24,0,4.8-0.64,7.04-1.6c5.44-2.56,8.96-8.32,8.96-14.4v-57.376l68.672,68.672 c3.136,3.136,7.232,4.704,11.328,4.704s8.192-1.568,11.328-4.672C449.589,437.131,449.589,427.019,443.349,420.747z"></path>
    </svg>
  );
};

export const UnmutedIcon = () => {
  return (
    <svg viewBox="0 0 480 480" xmlSpace="preserve">
      <path d="M278.944,17.577c-5.568-2.656-12.128-1.952-16.928,1.92L106.368,144.009H32c-17.632,0-32,14.368-32,32v128 c0,17.664,14.368,32,32,32h74.368l155.616,124.512c2.912,2.304,6.464,3.488,10.016,3.488c2.368,0,4.736-0.544,6.944-1.6 c5.536-2.656,9.056-8.256,9.056-14.4v-416C288,25.865,284.48,20.265,278.944,17.577z"></path>
      <path d="M368.992,126.857c-6.304-6.208-16.416-6.112-22.624,0.128c-6.208,6.304-6.144,16.416,0.128,22.656 C370.688,173.513,384,205.609,384,240.009s-13.312,66.496-37.504,90.368c-6.272,6.176-6.336,16.32-0.128,22.624 c3.136,3.168,7.264,4.736,11.36,4.736c4.064,0,8.128-1.536,11.264-4.64C399.328,323.241,416,283.049,416,240.009 S399.328,156.777,368.992,126.857z"></path>
      <path d="M414.144,81.769c-6.304-6.24-16.416-6.176-22.656,0.096c-6.208,6.272-6.144,16.416,0.096,22.624 C427.968,140.553,448,188.681,448,240.009s-20.032,99.424-56.416,135.488c-6.24,6.24-6.304,16.384-0.096,22.656 c3.168,3.136,7.264,4.704,11.36,4.704c4.064,0,8.16-1.536,11.296-4.64C456.64,356.137,480,299.945,480,240.009 S456.64,123.881,414.144,81.769z"></path>
    </svg>
  );
};

export const FullScreenIcon = () => {
  return (
    <svg viewBox="0 0 384 384" xmlSpace="preserve">
      <path d="M384.97,12.03c0-6.713-5.317-12.03-12.03-12.03H264.847c-6.833,0-11.922,5.39-11.934,12.223 c0,6.821,5.101,11.838,11.934,11.838h96.062l-0.193,96.519c0,6.833,5.197,12.03,12.03,12.03c6.833-0.012,12.03-5.197,12.03-12.03 l0.193-108.369c0-0.036-0.012-0.06-0.012-0.084C384.958,12.09,384.97,12.066,384.97,12.03z"></path>
      <path d="M120.496,0H12.403c-0.036,0-0.06,0.012-0.096,0.012C12.283,0.012,12.247,0,12.223,0C5.51,0,0.192,5.317,0.192,12.03 L0,120.399c0,6.833,5.39,11.934,12.223,11.934c6.821,0,11.838-5.101,11.838-11.934l0.192-96.339h96.242 c6.833,0,12.03-5.197,12.03-12.03C132.514,5.197,127.317,0,120.496,0z"></path>
      <path d="M120.123,360.909H24.061v-96.242c0-6.833-5.197-12.03-12.03-12.03S0,257.833,0,264.667v108.092 c0,0.036,0.012,0.06,0.012,0.084c0,0.036-0.012,0.06-0.012,0.096c0,6.713,5.317,12.03,12.03,12.03h108.092 c6.833,0,11.922-5.39,11.934-12.223C132.057,365.926,126.956,360.909,120.123,360.909z"></path>
      <path d="M372.747,252.913c-6.833,0-11.85,5.101-11.838,11.934v96.062h-96.242c-6.833,0-12.03,5.197-12.03,12.03 s5.197,12.03,12.03,12.03h108.092c0.036,0,0.06-0.012,0.084-0.012c0.036-0.012,0.06,0.012,0.096,0.012 c6.713,0,12.03-5.317,12.03-12.03V264.847C384.97,258.014,379.58,252.913,372.747,252.913z"></path>
    </svg>
  );
};

export const ExitFullScreenIcon = () => {
  return (
    <svg viewBox="0 0 385 385" xmlSpace="preserve">
      <path d="M264.943,156.665h108.273c6.833,0,11.934-5.39,11.934-12.211c0-6.833-5.101-11.85-11.934-11.838h-96.242V36.181 c0-6.833-5.197-12.03-12.03-12.03s-12.03,5.197-12.03,12.03v108.273c0,0.036,0.012,0.06,0.012,0.084 c0,0.036-0.012,0.06-0.012,0.096C252.913,151.347,258.23,156.677,264.943,156.665z"></path>
      <path d="M120.291,24.247c-6.821,0-11.838,5.113-11.838,11.934v96.242H12.03c-6.833,0-12.03,5.197-12.03,12.03 c0,6.833,5.197,12.03,12.03,12.03h108.273c0.036,0,0.06-0.012,0.084-0.012c0.036,0,0.06,0.012,0.096,0.012 c6.713,0,12.03-5.317,12.03-12.03V36.181C132.514,29.36,127.124,24.259,120.291,24.247z"></path>
      <path d="M120.387,228.666H12.115c-6.833,0.012-11.934,5.39-11.934,12.223c0,6.833,5.101,11.85,11.934,11.838h96.242v96.423 c0,6.833,5.197,12.03,12.03,12.03c6.833,0,12.03-5.197,12.03-12.03V240.877c0-0.036-0.012-0.06-0.012-0.084 c0-0.036,0.012-0.06,0.012-0.096C132.418,233.983,127.1,228.666,120.387,228.666z"></path>
      <path d="M373.3,228.666H265.028c-0.036,0-0.06,0.012-0.084,0.012c-0.036,0-0.06-0.012-0.096-0.012 c-6.713,0-12.03,5.317-12.03,12.03v108.273c0,6.833,5.39,11.922,12.223,11.934c6.821,0.012,11.838-5.101,11.838-11.922v-96.242 H373.3c6.833,0,12.03-5.197,12.03-12.03S380.134,228.678,373.3,228.666z"></path>
    </svg>
  );
};

export const LockedPlaylistIcon = () => {
  return (
    <svg
      height="512pt"
      viewBox="-64 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M336 512H48c-26.453 0-48-21.523-48-48V240c0-26.477 21.547-48 48-48h288c26.453 0 48 21.523 48 48v224c0 26.477-21.547 48-48 48zM48 224c-8.813 0-16 7.168-16 16v224c0 8.832 7.188 16 16 16h288c8.813 0 16-7.168 16-16V240c0-8.832-7.188-16-16-16zm0 0"></path>
      <path d="M304 224c-8.832 0-16-7.168-16-16v-80c0-52.93-43.07-96-96-96s-96 43.07-96 96v80c0 8.832-7.168 16-16 16s-16-7.168-16-16v-80C64 57.406 121.406 0 192 0s128 57.406 128 128v80c0 8.832-7.168 16-16 16zm0 0"></path>
    </svg>
  );
};

export const QueueLengthIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M9 16.985V6.964l9 5.157-9 4.864zm4-14.98c5.046.504 9 4.782 9 9.97a9.957 9.957 0 01-.892 4.113l1.738 1.006A11.98 11.98 0 0024 11.975C24 5.672 19.158.511 13 0v2.005zM2.891 16.087A9.955 9.955 0 012 11.975c0-5.188 3.954-9.466 9-9.97V0C4.842.511 0 5.672 0 11.975c0 1.833.421 3.563 1.153 5.118l1.738-1.006zm17.213 1.734c-1.817 2.523-4.769 4.175-8.104 4.175s-6.288-1.651-8.105-4.176l-1.746 1.011C4.316 21.953 7.917 24 12 24c4.082 0 7.683-2.047 9.851-5.168l-1.747-1.011z"></path>
    </svg>
  );
};

export const QueueDurationIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M458.406 380.681c-8.863-6.593-21.391-4.752-27.984 4.109A217.989 217.989 0 01418.889 399c-7.315 8.275-6.538 20.915 1.737 28.231a19.923 19.923 0 0013.239 5.016c5.532 0 11.04-2.283 14.992-6.754a257.803 257.803 0 0013.658-16.829c6.593-8.861 4.754-21.391-4.109-27.983zM491.854 286.886c-10.786-2.349-21.447 4.496-23.796 15.288a215.372 215.372 0 01-4.646 17.681c-3.261 10.554 2.651 21.752 13.204 25.013 1.967.607 3.955.896 5.911.896 8.54 0 16.448-5.514 19.102-14.102a255.373 255.373 0 005.514-20.98c2.349-10.792-4.496-21.446-15.289-23.796zM362.139 444.734a216.715 216.715 0 01-16.34 8.233c-10.067 4.546-14.542 16.392-9.996 26.459 3.34 7.396 10.619 11.773 18.239 11.773 2.752 0 5.549-.571 8.22-1.777a256.456 256.456 0 0019.377-9.764c9.645-5.384 13.098-17.568 7.712-27.212-5.383-9.643-17.567-13.096-27.212-7.712zM236 96v151.716l-73.339 73.338c-7.81 7.811-7.81 20.474 0 28.284a19.935 19.935 0 0014.143 5.858 19.943 19.943 0 0014.143-5.858l79.196-79.196A20.001 20.001 0 00276 256V96c0-11.046-8.954-20-20-20s-20 8.954-20 20z"></path>
      <path d="M492 43c-11.046 0-20 8.954-20 20v55.536C425.448 45.528 344.151 0 256 0 187.62 0 123.333 26.629 74.98 74.98 26.629 123.333 0 187.62 0 256s26.629 132.667 74.98 181.02C123.333 485.371 187.62 512 256 512c.169 0 .332-.021.5-.025.168.004.331.025.5.025 7.208 0 14.487-.304 21.637-.902 11.007-.922 19.183-10.592 18.262-21.599-.923-11.007-10.58-19.187-21.6-18.261-6.044.505-12.2.762-18.299.762-.169 0-.332.021-.5.025-.168-.004-.331-.025-.5-.025-119.103 0-216-96.897-216-216S136.897 40 256 40c76.758 0 147.357 40.913 185.936 106h-54.993c-11.046 0-20 8.954-20 20s8.954 20 20 20H448a63.612 63.612 0 0033.277-9.353 19.968 19.968 0 001.796-1.152C500.479 164.044 512 144.347 512 122V63c0-11.046-8.954-20-20-20z"></path>
    </svg>
  );
};

export const SkipIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#e8eaed"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M6 18l8.5-6L6 6v12zm2-8.14L11.03 12 8 14.14V9.86zM16 6h2v12h-2z" />
    </svg>
  );
};

export const DiscordIcon = () => {
  return (
    <svg
      height="512"
      viewBox="0 0 24 24"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#5c6bc0">
        <path d="M3.58 21.196h14.259l-.681-2.205C17.259 19.079 23 24 23 24V2.475C22.932 1.137 21.78 0 20.352 0L3.585.003C2.158.003 1 1.142 1 2.48v16.24c0 1.411 1.156 2.476 2.58 2.476zM14.128 5.683l-.033.012.012-.012zM6.497 6.952c1.833-1.334 3.532-1.27 3.532-1.27l.137.135c-2.243.535-3.26 1.537-3.26 1.537.104-.022 4.633-2.635 10.121.066 0 0-1.019-.937-3.124-1.537l.186-.183c.291.001 1.831.055 3.479 1.26 0 0 1.844 3.15 1.844 7.02-.061-.074-1.144 1.666-3.931 1.726 0 0-.472-.534-.808-1 1.63-.468 2.24-1.404 2.24-1.404-3.173 1.998-5.954 1.686-9.281.336-.031 0-.045-.014-.061-.03v-.006c-.016-.015-.03-.03-.061-.03h-.06c-.204-.134-.34-.2-.34-.2s.609.936 2.174 1.404a22.262 22.262 0 00-.818 1.002c-2.786-.066-3.802-1.806-3.802-1.806 0-3.876 1.833-7.02 1.833-7.02z"></path>
        <path d="M14.308 12.771c.711 0 1.29-.6 1.29-1.34 0-.735-.576-1.335-1.29-1.335v.003c-.708 0-1.288.598-1.29 1.338 0 .734.579 1.334 1.29 1.334zM9.69 12.771c.711 0 1.29-.6 1.29-1.34 0-.735-.575-1.335-1.286-1.335l-.004.003c-.711 0-1.29.598-1.29 1.338 0 .734.579 1.334 1.29 1.334z"></path>
      </g>
    </svg>
  );
};

export const DocumentIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path
        fill="#fff"
        d="M22 24H2V0h14l6 6v18zM15 1H3v22h18V7h-6V1zm3 15v1H6v-1h12zm0-3v1H6v-1h12zm0-3v1H6v-1h12zm-2-4h4.586L16 1.414V6z"
      ></path>
    </svg>
  );
};

export const ChatSettingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className="setting-icon"
    >
      <path d="M304 96H112c-8.832 0-16 7.168-16 16s7.168 16 16 16h192c8.832 0 16-7.168 16-16s-7.168-16-16-16zM240 160H112c-8.832 0-16 7.168-16 16s7.168 16 16 16h128c8.832 0 16-7.168 16-16s-7.168-16-16-16z"></path>
      <path d="M352 0H64C28.704 0 0 28.704 0 64v320c0 6.208 3.584 11.872 9.216 14.496A16.232 16.232 0 0016 400c3.68 0 7.328-1.28 10.24-3.712L117.792 320H352c35.296 0 64-28.704 64-64V64c0-35.296-28.704-64-64-64zm32 256c0 17.632-14.336 32-32 32H112c-3.744 0-7.36 1.312-10.24 3.712L32 349.856V64c0-17.632 14.336-32 32-32h288c17.664 0 32 14.368 32 32v192z"></path>
      <path d="M448 128c-8.832 0-16 7.168-16 16s7.168 16 16 16c17.664 0 32 14.368 32 32v270.688l-54.016-43.2A16.12 16.12 0 00416 416H192c-17.664 0-32-14.368-32-32v-16c0-8.832-7.168-16-16-16s-16 7.168-16 16v16c0 35.296 28.704 64 64 64h218.368l75.616 60.512A16.158 16.158 0 00496 512c2.336 0 4.704-.512 6.944-1.568A16.05 16.05 0 00512 496V192c0-35.296-28.704-64-64-64z"></path>
    </svg>
  );
};

export const TickIcon = () => {
  return (
    <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492">
      <path d="M484.128 104.478l-16.116-16.116c-5.064-5.068-11.816-7.856-19.024-7.856s-13.964 2.788-19.028 7.856L203.508 314.81 62.024 173.322c-5.064-5.06-11.82-7.852-19.028-7.852-7.204 0-13.956 2.792-19.024 7.852l-16.12 16.112C2.784 194.51 0 201.27 0 208.47c0 7.204 2.784 13.96 7.852 19.028l159.744 159.736c.212.3.436.58.696.836l16.12 15.852c5.064 5.048 11.82 7.572 19.084 7.572h.084c7.212 0 13.968-2.524 19.024-7.572l16.124-15.992c.26-.256.48-.468.612-.684l244.784-244.76c10.5-10.476 10.5-27.52.004-38.008z"></path>
    </svg>
  );
};

export const CameraSettingIcon = () => {
  return (
    <svg
      height="472pt"
      viewBox="0 -87 472 472"
      width="472pt"
      xmlns="http://www.w3.org/2000/svg"
      className="setting-icon"
    >
      <path d="M467.102 26.527a10.009 10.009 0 00-9.899-.18L348.906 85.48V50c-.031-27.602-22.398-49.969-50-50H50C22.398.031.031 22.398 0 50v197.422c.031 27.601 22.398 49.969 50 50h248.906c27.602-.031 49.969-22.399 50-50v-34.836l108.301 59.133A10.001 10.001 0 00472 262.94V35.125a9.997 9.997 0 00-4.898-8.598zM328.898 247.426c-.015 16.562-13.437 29.98-30 30H50c-16.563-.02-29.98-13.438-30-30V50c.02-16.559 13.438-29.98 30-30h248.906c16.559.02 29.98 13.441 30 30zM452 246.09L348.906 189.8v-81.534L452 51.98zm0 0"></path>
    </svg>
  );
};

export const QueueDialogIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 384"
      fill="#e8eaed"
    >
      <path d="M368 176c-8.832 0-16 7.168-16 16 0 88.224-71.776 160-160 160S32 280.224 32 192 103.776 32 192 32c42.952 0 83.272 16.784 113.544 47.264 6.216 6.272 16.352 6.312 22.624.08 6.272-6.224 6.304-16.352.08-22.624C291.928 20.144 243.536 0 192 0 86.128 0 0 86.128 0 192s86.128 192 192 192 192-86.128 192-192c0-8.832-7.168-16-16-16z"></path>
      <path d="M256 176h-48v-48c0-8.832-7.168-16-16-16s-16 7.168-16 16v48h-48c-8.832 0-16 7.168-16 16s7.168 16 16 16h48v48c0 8.832 7.168 16 16 16s16-7.168 16-16v-48h48c8.832 0 16-7.168 16-16s-7.168-16-16-16z"></path>
    </svg>
  );
};

export const QueueCloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <path d="M12.215 10.999l9.533-9.533A.86.86 0 1020.533.251L11 9.784 1.467.251A.86.86 0 00.251 1.467l9.533 9.532-9.533 9.534a.86.86 0 001.215 1.215L11 12.215l9.533 9.533a.86.86 0 001.215-1.215z"></path>
    </svg>
  );
};

export const LoginIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path
        fill="currentColor"
        d="M48 0L16 96v352h128v64h64l64-64h96l128-136.32V0H48zm416 288l-89.6 96H260.928L192 434.144V384H80V32h384v256z"
      ></path>
      <path
        fill="currentColor"
        d="M240 128h32v128h-32zM336 128h32v128h-32z"
      ></path>
    </svg>
  );
};

export const LogoutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path
        fill="currentColor"
        d="M255.15 468.625H63.787c-11.737 0-21.262-9.526-21.262-21.262V64.638c0-11.737 9.526-21.262 21.262-21.262H255.15c11.758 0 21.262-9.504 21.262-21.262S266.908.85 255.15.85H63.787C28.619.85 0 29.47 0 64.638v382.724c0 35.168 28.619 63.787 63.787 63.787H255.15c11.758 0 21.262-9.504 21.262-21.262 0-11.758-9.504-21.262-21.262-21.262z"
      ></path>
      <path
        fill="currentColor"
        d="M505.664 240.861L376.388 113.286c-8.335-8.25-21.815-8.143-30.065.213s-8.165 21.815.213 30.065l92.385 91.173H191.362c-11.758 0-21.262 9.504-21.262 21.262 0 11.758 9.504 21.263 21.262 21.263h247.559l-92.385 91.173c-8.377 8.25-8.441 21.709-.213 30.065a21.255 21.255 0 0015.139 6.336c5.401 0 10.801-2.041 14.926-6.124l129.276-127.575A21.303 21.303 0 00512 255.998c0-5.696-2.275-11.118-6.336-15.137z"
      ></path>
    </svg>
  );
};
export const DragHandleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 492.001 492.001"
      fill="white"
      className="move-window"
    >
      <path d="M487.97 237.06l-58.82-58.82c-5.224-5.228-14.376-5.228-19.592 0l-7.436 7.432c-5.4 5.4-5.4 14.064 0 19.46l21.872 21.74H265.206V68.396l21.808 22.132c5.224 5.22 14.216 5.22 19.428 0l7.36-7.432c5.404-5.404 5.356-14.196-.044-19.596L254.846 4.444c-2.6-2.592-6.088-4.184-9.804-4.184h-.404c-3.712 0-7.188 1.588-9.784 4.184l-57.688 57.772c-2.612 2.608-4.052 6.124-4.052 9.836 0 3.704 1.44 7.208 4.052 9.816l7.432 7.444c5.224 5.22 14.612 5.228 19.828.004l22.368-22.132v159.688H67.814l22.14-22.008c2.608-2.608 4.048-6.028 4.048-9.732s-1.44-7.16-4.052-9.76l-7.436-7.42c-5.22-5.216-14.372-5.2-19.584.008L4.034 236.856c-2.672 2.672-4.1 6.244-4.032 9.92-.068 3.816 1.356 7.388 4.028 10.056l57.68 57.692c5.224 5.22 14.38 5.22 19.596 0l7.44-7.44a13.75 13.75 0 004.044-9.788c0-3.716-1.44-7.232-4.044-9.836l-22.14-22.172H226.79V425.32l-23.336-23.088c-5.212-5.22-14.488-5.22-19.7 0l-7.5 7.44a13.778 13.778 0 00-4.072 9.792c0 3.704 1.424 7.184 4.028 9.792l58.448 58.456c2.596 2.592 6.068 4.028 9.9 4.028.024-.016.24 0 .272 0 3.712 0 7.192-1.432 9.792-4.028l58.828-58.832a13.782 13.782 0 004.044-9.792c0-3.712-1.44-7.192-4.044-9.796l-7.44-7.44c-5.216-5.22-14.044-5.22-19.264 0l-21.54 21.868V265.284H425.59l-23.096 23.132c-2.612 2.608-4.048 6.112-4.048 9.82s1.432 7.192 4.048 9.8l7.44 7.444c5.212 5.224 14.372 5.224 19.584 0l58.452-58.452a13.633 13.633 0 004.028-9.916c.072-3.816-1.356-7.384-4.028-10.052z"></path>
    </svg>
  );
};

export const MoreIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.055 32.055"
      fill="currentColor"
    >
      <path d="M3.968 12.061A3.965 3.965 0 000 16.027a3.965 3.965 0 003.968 3.967 3.966 3.966 0 100-7.933zm12.265 0a3.967 3.967 0 00-3.968 3.965c0 2.192 1.778 3.967 3.968 3.967s3.97-1.772 3.97-3.967a3.97 3.97 0 00-3.97-3.965zm11.857 0a3.967 3.967 0 10-.005 7.933 3.967 3.967 0 00.005-7.933z"></path>
    </svg>
  );
};

export const CopyButtonIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488.3 488.3">
      <path d="M314.25 85.4h-227c-21.3 0-38.6 17.3-38.6 38.6v325.7c0 21.3 17.3 38.6 38.6 38.6h227c21.3 0 38.6-17.3 38.6-38.6V124c-.1-21.3-17.4-38.6-38.6-38.6zm11.5 364.2c0 6.4-5.2 11.6-11.6 11.6h-227c-6.4 0-11.6-5.2-11.6-11.6V124c0-6.4 5.2-11.6 11.6-11.6h227c6.4 0 11.6 5.2 11.6 11.6v325.6z"></path>
      <path d="M401.05 0h-227c-21.3 0-38.6 17.3-38.6 38.6 0 7.5 6 13.5 13.5 13.5s13.5-6 13.5-13.5c0-6.4 5.2-11.6 11.6-11.6h227c6.4 0 11.6 5.2 11.6 11.6v325.7c0 6.4-5.2 11.6-11.6 11.6-7.5 0-13.5 6-13.5 13.5s6 13.5 13.5 13.5c21.3 0 38.6-17.3 38.6-38.6V38.6c0-21.3-17.3-38.6-38.6-38.6z"></path>
    </svg>
  );
};

export const DeleteButtonIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="32px"
      viewBox="0 0 24 24"
      width="32x"
      fill="#e8eaed"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
    </svg>
  );
};

export const UnlockedPlaylistIcon = () => {
  return (
    <svg
      height="512pt"
      viewBox="-64 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M336 512H48c-26.453 0-48-21.523-48-48V240c0-26.477 21.547-48 48-48h288c26.453 0 48 21.523 48 48v224c0 26.477-21.547 48-48 48zM48 224c-8.813 0-16 7.168-16 16v224c0 8.832 7.188 16 16 16h288c8.813 0 16-7.168 16-16V240c0-8.832-7.188-16-16-16zm0 0"></path>
      <path d="M80 224c-8.832 0-16-7.168-16-16v-80C64 57.406 121.406 0 192 0s128 57.406 128 128c0 8.832-7.168 16-16 16s-16-7.168-16-16c0-52.93-43.07-96-96-96s-96 43.07-96 96v80c0 8.832-7.168 16-16 16zm0 0"></path>
    </svg>
  );
};

export const DelayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#e8eaed"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
    </svg>
  );
};
