import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import ReactPlayer from "react-player";
import "./Player.css";
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  DragDropContext,
  DraggableProvided,
  DraggableStateSnapshot,
  Draggable,
  DropResult,
  Droppable,
  DroppableProvided,
  DraggableStyle,
} from "@hello-pangea/dnd";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React from "react";
import { SocketContext, useSocket, VisibilityContext } from "../../App";
import { jwtDecode } from "jwt-decode";
import {
  PauseIcon,
  PlayIcon,
  MutedIcon,
  UnmutedIcon,
  FullScreenIcon,
  LockedPlaylistIcon,
  QueueLengthIcon,
  QueueDurationIcon,
  SkipIcon,
  ChatSettingIcon,
  TickIcon,
  CameraSettingIcon,
  QueueDialogIcon,
  QueueCloseIcon,
  LoginIcon,
  LogoutIcon,
  ExitFullScreenIcon,
  MoreIcon,
  DeleteButtonIcon,
  UnlockedPlaylistIcon,
  DelayIcon,
} from "./Svg";
import { QueueItem, AppState, User } from "./Interfaces";
import { getDelay, toggleDelay } from "./StreamDelay";

const renderWrapper = (
  children: JSX.Element,
  providedMain: DroppableProvided
) => (
  <div
    className="video-list-container"
    ref={providedMain.innerRef}
    {...providedMain.droppableProps}
  >
    {children}
  </div>
);

const getItemStyle = (
  isDragging: boolean,
  draggableStyle?: DraggableStyle
) => ({
  paddingBottom: "15px",
  opacity: isDragging ? "0.2" : "1",

  ...draggableStyle,
});

const renderQueueItem = (
  item: QueueItem | undefined,
  provided: DraggableProvided,
  snapshot: DraggableStateSnapshot,
  index: number,
  playedSeconds: number
) => {
  if (!item || !item.url) return null;
  const className = `playlist-item ${index === -1 ? "previous" : ""} ${
    index === 0 ? "current" : ""
  }`;
  const defaultThumbnail =
    "https://cdn.7tv.app/emote/66523b0ca4cae22f82d841b0/4x.webp";
  const useDefaultThumbnail = !item.thumbnail;
  // const broadcasterIconUrl = "https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/1"
  // const modIconUrl = "https://static-cdn.jtvnw.net/badges/v1/3267646d-33f0-4b17-b3df-f923a41db1d0/1"
  var roleIconUrl = "";

  if (item.user?.username === "nezzula") {
    roleIconUrl =
      "https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/1";
  } else if (item.user?.isAdmin) {
    roleIconUrl =
      "https://static-cdn.jtvnw.net/badges/v1/3267646d-33f0-4b17-b3df-f923a41db1d0/1";
  }

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
    >
      <article className={className}>
        <a
          className="playlist-thumbnail"
          href={item.originalUrl ?? item.url}
          target="_blank"
          rel="noreferrer"
        >
          <span>
            <img
              src={item.thumbnail ?? defaultThumbnail}
              decoding="async"
              data-nimg="fill"
              alt=""
              style={
                useDefaultThumbnail
                  ? { transform: "scale(0.6)", objectFit: "contain" }
                  : { objectFit: "cover" }
              }
            />
          </span>
          <time className="playlist-item-duration">
            <p>{formatTime(item.duration ?? 0)}</p>
          </time>
        </a>
        <div className="playlist-item-data">
          <a
            href={item.originalUrl ?? item.url}
            target="_blank"
            rel="noreferrer"
            className="playlist-item-title"
          >
            {item.title}
          </a>
          <div className="rp-ui__seek-slider playlist-item-slider">
            <progress
              className="rp-ui__seek-slider__buffer"
              value={index === 0 ? playedSeconds : 0}
              max={item.duration}
              aria-hidden={true}
            ></progress>
          </div>
          <div className="playlist-item-requester-wrapper">
            <span>Dodane przez:</span>
            <div className="playlist-item-requester">
              {item.user?.isAdmin && (
                <span className="playlist-item-role-wrapper">
                  <span>
                    <span>
                      <img
                        alt=""
                        aria-hidden="true"
                        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMzYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIi8+"
                      />
                    </span>
                    <img
                      src={roleIconUrl}
                      decoding="async"
                      data-nimg="intrinsic"
                      alt=""
                    />
                  </span>
                </span>
              )}
              <div className="playlist-item-requester-name">
                {item.user?.username}
              </div>
            </div>
          </div>
          <MoreMenu userId={item.user?.id ?? 0} videoId={item.id} />
        </div>
      </article>
    </div>
  );
};

function MoreMenu({ userId, videoId }: { userId: number; videoId: string }) {
  const socket = useSocket();

  const handleClick = (_event: React.MouseEvent<HTMLElement>) => {
    socket?.emit("videoRemove", videoId);
  };

  const canRemove = +userId === checkUserId() || checkMod();

  useEffect(() => {
    console.log(canRemove, userId, checkUserId(), checkMod());
  }, [canRemove, userId]);

  return (
    <div>
      {canRemove && (
        <Button
          className="more-button"
          size="medium"
          type="button"
          onClick={handleClick}
          style={{ padding: "0px 4px", borderRadius: 99 }}
        >
          <DeleteButtonIcon />
        </Button>
      )}
    </div>
  );
}

const getVideoId = (url: string) => {
  if (url.includes("youtube")) {
    return url.slice(-11);
  } else if (url.includes("streamable")) {
    const parts = url.split("/");
    const lastPart = parts.at(-1);
    return lastPart !== undefined ? lastPart : url;
  } else {
    return url;
  }
};

const formatTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  if (hours > 0)
    return [
      hours.toString().padStart(1, "0"),
      minutes.toString().padStart(2, "0"),
      remainingSeconds.toString().padStart(2, "0"),
    ].join(":");
  return [
    minutes.toString().padStart(2, "0"),
    remainingSeconds.toString().padStart(2, "0"),
  ].join(":");
};

const checkMod = () => {
  const storedToken = localStorage.getItem("token");
  if (storedToken) {
    const { isAdmin } = jwtDecode(storedToken) as { isAdmin: boolean };

    return isAdmin;
  }
  return false;
};

const checkLogin = () => {
  return !!localStorage.getItem("token");
};

const checkUserId = () => {
  const storedToken = localStorage.getItem("token");
  if (storedToken) {
    const { sub } = jwtDecode(storedToken) as { sub: number };

    return +sub;
  }
  return 0;
};

const checkUserName = () => {
  const storedToken = localStorage.getItem("token");
  if (storedToken) {
    const { username } = jwtDecode(storedToken) as { username: string };

    return username;
  }
  return "";
};

export default function Player() {
  const [state, setState] = useState<AppState>({
    queue: [],
  });
  const playerRef = useRef() as MutableRefObject<ReactPlayer>;
  const [currentUrl, setCurrentUrl] = useState("");
  const [currentTitle, setCurrentTitle] = useState("");
  const [playing, setPlaying] = useState(true);
  const [muted, setMuted] = useState(true);
  const [hovered, setHovered] = useState(false);
  const [mutedJustJoined, setMutedJustJoined] = useState(true);
  const [durationSeconds, setDurationSeconds] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [volume, setVolume] = useState(0.5);
  const [loadedSeconds, setLoadedSeconds] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [playlistLocked, setPlaylistLocked] = useState(true);
  const socket = useSocket();

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        socket.emit("getQueue");
      });

      socket.emit("getQueue");

      socket.on("queueData", (data) => {
        setState((prevState) => ({
          ...prevState,
          queue: data,
        }));
        if (data.length === 0) setCurrentUrl("");
        if (data.length > 0) {
          setCurrentUrl(data[0].url);
          setCurrentTitle(data[0].title);
        }
        setTotalTime(calculateTotalDuration(data));
        // console.log("got", data);
      });

      socket.on("previousData", (data) => {
        setState((prevState) => ({
          ...prevState,
          previous: data,
        }));
      });

      socket.on("playbackState", (data) => {
        setPlayedSeconds(data.playedSeconds + getDelay());
        playerRef.current.seekTo(data.playedSeconds + getDelay());
        setPlaying(data.playing);
      });

      socket.on("playlistLock", (data) => {
        setPlaylistLocked(data);
      });

      return () => {
        socket.disconnect();
        socket.off("connect");
        socket.off("queueData");
        socket.off("previousData");
        socket.off("playbackState");
        socket.off("playlistLock");
      };
    }
  }, [socket]);

  function calculateTotalDuration(queue: QueueItem[]): number {
    return queue.reduce((total, item) => total + (item.duration || 0), 0);
  }

  const queueSkip = useCallback(() => {
    if (checkMod()) {
      socket?.emit("skipVideo");
    }
  }, [socket]);

  const handleLockClick = () => {
    if (checkMod()) {
      socket?.emit("togglePlaylist", !playlistLocked);
    }
  };

  const lockedPlaylistClass = () => {
    return playlistLocked ? "lock-button locked" : "lock-button";
  };

  return (
    <div id="content" className="content">
      <SimpleBar className="simplebar">
        <div className="player">
          <div
            className="rp-ui"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <div className="rp-ui__player">
              <ReactPlayer
                ref={playerRef}
                url={currentUrl}
                width="100%"
                height="100vh"
                muted={muted}
                playing={playing}
                controls={false}
                volume={volume}
                // onEnded={queueSkip}
                onProgress={({ playedSeconds, loadedSeconds }) => {
                  setPlayedSeconds(playedSeconds);
                  setLoadedSeconds(loadedSeconds);
                }}
                onSeek={setPlayedSeconds}
                onDuration={setDurationSeconds}
              />
            </div>
            <>
              <Controls
                playerRef={playerRef}
                playing={playing}
                setPlaying={setPlaying}
                playedSeconds={playedSeconds}
                loadedSeconds={loadedSeconds}
                duration={durationSeconds}
                title={currentTitle}
                muted={muted}
                setMuted={setMuted}
                mutedJustJoined={mutedJustJoined}
                setMutedJustJoined={setMutedJustJoined}
                hovered={hovered}
                volume={volume}
                setVolume={setVolume}
              />
            </>
            <div className="rp-ui__controls-bottom-slider"></div>
          </div>
        </div>
        <div className="bottom-wrapper">
          <div className="bottom-content">
            <div className="playlist-header">
              <div className="playlist-header-element-wrapper">
                <div className="playlist-header-element">
                  <Button
                    size="medium"
                    className={lockedPlaylistClass()}
                    variant="contained"
                    onClick={handleLockClick}
                    style={checkMod() ? { pointerEvents: "all" } : {}}
                  >
                    {playlistLocked ? (
                      <LockedPlaylistIcon />
                    ) : (
                      <UnlockedPlaylistIcon />
                    )}
                  </Button>
                  <h1 className="playlist-header-text">Playlista</h1>
                </div>
                <span className="playlist-header-separator"></span>
                <div className="playlist-header-element">
                  <div className="playlist-header-data-wrapper">
                    <div className="playlist-header-data">
                      <QueueLengthIcon />
                      <span>
                        <QueueLength queue={state.queue} />
                      </span>
                    </div>
                    <div className="playlist-header-data">
                      <QueueDurationIcon />
                      <span>{formatTime(totalTime)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="playlist-header-element">
                <LoginButton
                  state={state}
                  queueSkip={queueSkip}
                  locked={playlistLocked}
                />
              </div>
            </div>
            <div className="bottom-content-wrapper content-row">
              <div className="playlist-wrapper">
                <div className="playlist">
                  <Playlist
                    queue={state.queue}
                    previous={state.previous}
                    playedSeconds={playedSeconds}
                  />
                </div>
              </div>
              <div className="right-wrapper">
                <div className="plan-wrapper">
                  <div className="plan-row">
                    <h2 className="settings">Ustawienia</h2>
                    <Settings />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer-wrapper">
            {/* <ul className="footer-list">
              <a
                href="https://discord.gg/A3myyYMak2"
                target="_blank"
                rel="noreferrer noopener"
                className="footer-text"
              >
                <DiscordIcon />
                <span>Discord</span>
              </a>
              <a
                href="/regulamin.pdf"
                target="_blank"
                rel="noreferrer noopener"
                className="footer-text"
              >
                <DocumentIcon />
                <span>Regulamin</span>
              </a>
              <a
                href="/polityka-prywatnosci.pdf"
                target="_blank"
                rel="noreferrer noopener"
                className="footer-text"
              >
                <DocumentIcon />
                <span>Polityka prywatności</span>
              </a>
              <a
                href="/dmca.pdf"
                target="_blank"
                rel="noreferrer noopener"
                className="footer-text"
              >
                <DocumentIcon />
                <span>DMCA</span>
              </a>
            </ul> */}
          </footer>
        </div>
      </SimpleBar>
    </div>
  );
}

function QueueLength({ queue }: { queue: QueueItem[] }) {
  const validQueue = queue.filter((item) => item.url !== "");

  return <>{validQueue.length}</>;
}

function Playlist({
  queue,
  previous,
  playedSeconds,
}: {
  queue: QueueItem[];
  previous: QueueItem | undefined;
  playedSeconds: number;
}) {
  const [isMod, setIsMod] = useState(false);
  const [emptyQueue, setEmptyQueue] = useState(true);
  const socket = useSocket();

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(queue);
    const [removed] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, removed);

    socket?.emit("updateQueue", items);
  };

  useEffect(() => {
    setIsMod(checkMod());
  }, []);

  useEffect(() => {
    setEmptyQueue(queue.filter((item) => item.url).length === 0);
  }, [queue]);

  return (
    <>
      {!emptyQueue ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(providedMain) =>
              renderWrapper(
                <>
                  {previous && (
                    <Draggable
                      key={previous.id}
                      index={-1}
                      draggableId={previous.id}
                      isDragDisabled={true}
                    >
                      {(provided, snapshot) =>
                        renderQueueItem(previous, provided, snapshot, -1, 0)
                      }
                    </Draggable>
                  )}
                  {queue
                    .filter((item) => item.url)
                    .map((item, index) => (
                      <Draggable
                        key={item.id}
                        index={index}
                        draggableId={item.id}
                        isDragDisabled={!isMod}
                      >
                        {(provided, snapshot) =>
                          renderQueueItem(
                            item,
                            provided,
                            snapshot,
                            index,
                            playedSeconds
                          )
                        }
                      </Draggable>
                    ))}

                  {providedMain.placeholder}
                </>,
                providedMain
              )
            }
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="empty-playlist-wrapper">
          <h2 className="empty-playlist-text">
            <div className="empty-playlist-icon">
              <span>
                <img
                  src="https://cdn.7tv.app/emote/603cac391cd55c0014d989be/4x.webp"
                  decoding="async"
                  alt=""
                />
              </span>
            </div>
            Playlista jest pusta
          </h2>
        </div>
      )}
    </>
  );
}

function Settings() {
  const { showChat, setShowChat, showStreamOverlay, setShowStreamOverlay } =
    useContext(VisibilityContext);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "streamOverlay") {
      setShowStreamOverlay(event.target.checked);
    } else if (event.target.name === "chat") {
      setShowChat(event.target.checked);
    }
  };

  useEffect(() => {
    const chatVisibility = localStorage.getItem("chat-visibility");
    if (chatVisibility) {
      setShowChat(JSON.parse(chatVisibility));
    }

    const streamVisibility = localStorage.getItem("stream-visibility");
    if (streamVisibility) {
      setShowStreamOverlay(JSON.parse(streamVisibility));
    }
  });

  const streamCheckboxRef = useRef<HTMLInputElement>(null);
  const chatCheckboxRef = useRef<HTMLInputElement>(null);

  const handleStreamClick = () => {
    if (streamCheckboxRef.current) {
      streamCheckboxRef.current.checked = !streamCheckboxRef.current.checked;
      localStorage.setItem(
        "stream-visibility",
        String(streamCheckboxRef.current.checked)
      );
      setShowStreamOverlay(streamCheckboxRef.current.checked);
    }
  };

  const handleChatClick = () => {
    if (chatCheckboxRef.current) {
      chatCheckboxRef.current.checked = !chatCheckboxRef.current.checked;
      localStorage.setItem(
        "chat-visibility",
        String(chatCheckboxRef.current.checked)
      );
      setShowChat(chatCheckboxRef.current.checked);
    }
  };

  return (
    <div>
      <div className="setting-wrapper" onClick={handleChatClick}>
        <div className="setting-content">
          <div className="setting-text">
            <div className="setting-text-wrapper">
              <ChatSettingIcon />
              <div>
                <h3 className="header-3">Czat</h3>
                <p className="paragraph">Włącz/wyłącz widoczność czatu</p>
              </div>
            </div>
          </div>
          <div className="setting-tick">
            <label className="tick-wrapper" htmlFor="chat-visibility">
              <input
                id="chat-visibility"
                className="tick-input"
                type="checkbox"
                name="chat"
                onChange={handleCheckboxChange}
                checked={showChat}
                ref={chatCheckboxRef}
                readOnly
              ></input>
              <div className="tick-mark">{showChat && <TickIcon />}</div>
              <p className="tick"></p>
            </label>
          </div>
        </div>
      </div>
      <div className="setting-wrapper" onClick={handleStreamClick}>
        <div className="setting-content">
          <div className="setting-text">
            <div className="setting-text-wrapper">
              <CameraSettingIcon />
              <div>
                <h3 className="header-3">Kamera</h3>
                <p className="paragraph">Włącz/wyłącz widoczność kamery</p>
              </div>
            </div>
          </div>
          <div className="setting-tick">
            <label className="tick-wrapper" htmlFor="stream-visibility">
              <input
                id="stream-visibility"
                className="tick-input"
                type="checkbox"
                name="streamOverlay"
                onChange={handleCheckboxChange}
                checked={showStreamOverlay}
                ref={streamCheckboxRef}
                readOnly
              ></input>
              <div className="tick-mark">
                {showStreamOverlay && <TickIcon />}
              </div>
              <p className="tick"></p>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

function QueueDialog({
  state,
  locked,
  isMod,
}: {
  state: AppState;
  locked: boolean;
  isMod: boolean;
}) {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const formRef = useRef<HTMLFormElement>(null);
  const dialogRef = useRef<HTMLDialogElement>(null);
  const socket = useSocket();

  const openDialog = () => {
    if (dialogRef.current) {
      dialogRef.current.showModal();
    }
  };

  const closeDialog = useCallback(() => {
    if (dialogRef.current) {
      dialogRef.current.close();
    }
  }, []);

  const queueAdd = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isMod) {
      if (locked) return;
    }
    if (!url) return;
    if (state.queue.some((item) => item.url === url)) return;

    let newQueueItem: QueueItem = {
      id: getVideoId(url),
      url: url,
      title: title ? title : "untitled",
    };

    if (socket) socket.emit("addToQueue", newQueueItem);

    closeDialog();
    setTitle("");
    setUrl("");
  };

  useEffect(() => {
    function handleClick(event: MouseEvent) {
      if (locked) return;

      const dialog = dialogRef.current;
      const queueDialog = dialog?.querySelector(".queue-dialog");

      const clickInsideQueueDialog = queueDialog?.contains(
        event.target as Node
      );
      const clickInsideDialog = dialog?.contains(event.target as Node);

      if (
        dialog &&
        dialog.open &&
        !clickInsideQueueDialog &&
        clickInsideDialog
      ) {
        closeDialog();
      }
    }

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [closeDialog, locked]);

  return (
    <>
      {checkLogin() && (
        <Button
          size="medium"
          startIcon={<QueueDialogIcon />}
          variant="contained"
          type="button"
          onClick={openDialog}
          className="add-button"
          disabled={isMod ? false : locked}
        >
          Dodaj film do kolejki
        </Button>
      )}

      <dialog ref={dialogRef} className={dialogRef.current?.open ? "open" : ""}>
        <div className="dialog-wrapper">
          <div className="queue-dialog">
            <div className="dialog-header">
              <h2 className="dialog-header-text">Dodaj film</h2>
              <Button
                size="medium"
                variant="contained"
                type="button"
                onClick={closeDialog}
                className="close-button"
                style={{ minWidth: 0 }}
              >
                <QueueCloseIcon />
              </Button>
            </div>
            <form className="dialog-form" onSubmit={queueAdd} ref={formRef}>
              <div className="dialog-controls">
                <TextField
                  label="Tytuł"
                  variant="outlined"
                  className="dialog-textfield"
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                ></TextField>
                <TextField
                  label="URL"
                  variant="outlined"
                  className="dialog-textfield"
                  value={url}
                  onChange={(event) => setUrl(event.target.value)}
                ></TextField>
              </div>
              <div className="dialog-close">
                <Button
                  size="medium"
                  variant="contained"
                  type="submit"
                  className="queue-button"
                >
                  Dodaj
                </Button>
              </div>
            </form>
          </div>
        </div>
      </dialog>
    </>
  );
}

function LoginButton({
  state,
  queueSkip,
  locked,
}: {
  state: AppState;
  queueSkip: any;
  locked: boolean;
}) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isBanned, setIsBanned] = useState(false);
  const [isMod, setIsMod] = useState(false);
  const [isDelay, setIsDelay] = useState(false);
  const context = useContext(SocketContext);

  const handleLogoutClick = useCallback(() => {
    localStorage.removeItem("token");
    context?.disconnectSocket();
    context?.connectSocket();
    setIsLoggedIn(false);
  }, [context]);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setIsLoggedIn(true);
      handleSetMod(storedToken);
      // return;
    }

    context?.socket?.on("adminUpdate", (data) => {
      // console.log(data);
      if (storedToken) {
        const { username } = jwtDecode(storedToken) as User;
        if (data.username === username) {
          setIsBanned(data.action === "ban");
          setIsMod(data.action === "mod");
        }
      }
    });

    context?.socket?.on("invalidCredentials", () => {
      // console.log("xd2");
      // const url = `${process.env.REACT_APP_API_URL}/auth/twitch`;
      // window.open(url, "_blank")?.focus();
      handleLogoutClick();
      // handleLoginClick();
    });

    const handleTokenMessage = (event: any) => {
      if (event.data.type === "setToken") {
        localStorage.setItem("token", event.data.token);
        context?.disconnectSocket();
        context?.connectSocket(event.data.token);
        handleSetMod(event.data.token);
        setIsLoggedIn(true);
      }
    };

    window.addEventListener("message", handleTokenMessage);
    return () => {
      window.removeEventListener("message", handleTokenMessage);
      context?.socket?.off("adminUpdate");
      context?.socket?.off("invalidCredentials");
    };
  }, [context, handleLogoutClick]);

  const handleSetMod = (token: string) => {
    const decoded = jwtDecode(token) as User;
    setIsBanned(decoded.isBanned);
    setIsMod(decoded.isAdmin);
  };

  const handleLoginClick = () => {
    const newWindow = window.open(
      `${process.env.REACT_APP_API_URL}/auth/twitch`,
      "_blank",
      "width=800,height=600,menubar=no,toolbar=no,location=no,status=no"
    );

    if (newWindow) newWindow.focus();
  };

  const isStreamer = () => {
    return checkUserName().toLowerCase() === "nezzula";
  };

  const handleToggleDelay = () => {
    toggleDelay();
    setIsDelay(!isDelay);
  };

  return (
    <>
      {!isLoggedIn ? (
        <Button
          size="medium"
          startIcon={<LoginIcon />}
          className="login-button"
          variant="contained"
          type="button"
          onClick={handleLoginClick}
        >
          Zaloguj się
        </Button>
      ) : (
        <>
          <AdminTools />
          {isStreamer() && (
            <Button
              size="medium"
              startIcon={<DelayIcon />}
              variant="contained"
              type="button"
              className="add-button"
              onClick={handleToggleDelay}
            >
              {isDelay ? "Wyłącz delay" : "Włącz delay"}
            </Button>
          )}
          {isMod && (
            <Button
              size="medium"
              startIcon={<SkipIcon />}
              variant="contained"
              type="button"
              className="add-button"
              onClick={queueSkip}
            >
              Pomiń film
            </Button>
          )}

          <QueueDialog
            state={state}
            locked={locked || isBanned}
            isMod={isMod}
          />
          <Button
            size="medium"
            startIcon={<LogoutIcon />}
            className="logout-button"
            variant="contained"
            type="button"
            onClick={handleLogoutClick}
          >
            Wyloguj się
          </Button>
        </>
      )}
    </>
  );
}

function AdminTools() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [username, setUsername] = useState("");
  const [action, setAction] = useState<"mod" | "ban" | "none">("none");
  const [dialogOpen, setDialogOpen] = useState(false);
  const usernameInputRef = useRef<HTMLInputElement>(null);
  const socket = useSocket();

  useEffect(() => {
    setIsAdmin(checkUserId() === 81220570);
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isAdmin) {
      socket?.emit("adminAction", { username, action });
    }
    handleCloseDialog();
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleActionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAction(event.target.value as "mod" | "ban" | "none");
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <>
      {isAdmin ? (
        <>
          <Button
            size="medium"
            startIcon={<MoreIcon />}
            variant="contained"
            type="button"
            className="add-button"
            onClick={() => setDialogOpen(true)}
          >
            Admin
          </Button>

          <Dialog open={dialogOpen} onClose={handleCloseDialog}>
            <DialogTitle id="user-action-dialog-title">Admin tools</DialogTitle>
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="username"
                  label="Username"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={username}
                  onChange={handleUsernameChange}
                  inputRef={usernameInputRef}
                />

                <FormControl component="fieldset" style={{ marginTop: "16px" }}>
                  <FormLabel component="legend">Action</FormLabel>
                  <RadioGroup
                    aria-label="action"
                    name="action"
                    value={action}
                    onChange={handleActionChange}
                    row
                  >
                    <FormControlLabel
                      value="none"
                      control={<Radio />}
                      label="None"
                    />
                    <FormControlLabel
                      value="mod"
                      control={<Radio />}
                      label="Mod"
                    />
                    <FormControlLabel
                      value="ban"
                      control={<Radio />}
                      label="Ban"
                    />
                  </RadioGroup>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog}>Cancel</Button>
                <Button type="submit">Submit</Button>
              </DialogActions>
            </form>
          </Dialog>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

type ControlsProps = {
  playing: boolean;
  setPlaying: Dispatch<SetStateAction<boolean>>;
  playedSeconds: number;
  loadedSeconds: number;
  duration: number;
  playerRef: MutableRefObject<ReactPlayer>;
  title: string;
  muted: boolean;
  setMuted: Dispatch<SetStateAction<boolean>>;
  mutedJustJoined: boolean;
  setMutedJustJoined: Dispatch<SetStateAction<boolean>>;
  hovered: boolean;
  volume: number;
  setVolume: Dispatch<SetStateAction<number>>;
};

const Controls = (props: ControlsProps) => {
  const controlsRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<HTMLDivElement>(null);
  const bottomSliderRef = useRef<HTMLDivElement>(null);
  const volumeRef = useRef<HTMLInputElement>(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isMod, setIsMod] = useState(false);
  const socket = useSocket();
  const { setVolume } = props;

  const seek = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isMod) {
      props.playerRef.current.seekTo(+e.target.value, "seconds");
      const calculatedSeconds = +e.target.value - getDelay();
      socket?.emit("updatePlaybackState", {
        playing: props.playing,
        playedSeconds: calculatedSeconds,
      });
    }
  };

  const changeVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setVolume(+e.target.value);
    localStorage.setItem("volume", e.target.value);
    if (+e.target.value === 0) props.setMuted(true);
    if (+e.target.value > 0) props.setMuted(false);
  };

  const togglePlayback = useCallback(() => {
    if (isMod) {
      const calculatedSeconds = props.playedSeconds - getDelay();
      socket?.emit("updatePlaybackState", {
        playing: !props.playing,
        playedSeconds: calculatedSeconds,
      });
    } else if (!props.playing) {
      socket?.emit("getPlaybackState");
    } else {
      props.setPlaying(!props.playing);
    }
  }, [isMod, props, socket]);

  useEffect(() => {
    const storedVolume = localStorage.getItem("volume");
    if (storedVolume) {
      setVolume(+storedVolume);
    }
  }, [setVolume]);

  useEffect(() => {
    setIsMod(checkMod());

    function handleClick(event: MouseEvent) {
      const controls = controlsRef.current;
      const controlsBar = controls?.querySelector(".rp-ui__controls-bottom");

      const clickInsideControls = controls?.contains(event.target as Node);
      const clickInsideControlsBar = controlsBar?.contains(
        event.target as Node
      );

      if (
        controls &&
        clickInsideControls &&
        !props.mutedJustJoined &&
        !clickInsideControlsBar
      ) {
        togglePlayback();
      }

      if (controls && clickInsideControls && props.mutedJustJoined) {
        props.setMuted(false);
        props.setMutedJustJoined(false);
      }
    }

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [props, togglePlayback]);

  useEffect(() => {
    const sliderEl = sliderRef.current;
    if (sliderEl && props.duration) {
      const progressPercentage = (props.playedSeconds / props.duration) * 100;
      sliderEl.style.setProperty(
        "--value",
        `${progressPercentage.toFixed(2)}%`
      );
    }
    const bottomSliderEl = bottomSliderRef.current;
    if (bottomSliderEl && props.duration) {
      const progressPercentage = (props.playedSeconds / props.duration) * 100;
      bottomSliderEl.style.setProperty(
        "--value",
        `${progressPercentage.toFixed(2)}%`
      );
    }
  }, [props.playedSeconds, props.duration]);

  useEffect(() => {
    const volumeEl = volumeRef.current;
    if (volumeEl) {
      const volumePercentage = props.volume * 100;
      volumeEl.style.setProperty("--value", `${volumePercentage.toFixed(0)}%`);
    }
  }, [props.volume]);

  const playingClass = () => {
    return props.playing ? " rp-ui__controls--playing" : "";
  };

  const controlsVisibleClass = () => {
    return props.hovered || props.mutedJustJoined || !props.playing
      ? " rp-ui__controls--visible"
      : "";
  };

  const toggleFullScreen = () => {
    isFullScreen
      ? document.exitFullscreen()
      : document.body.requestFullscreen();
    setIsFullScreen(!isFullScreen);
  };

  const sliderVisibleClass = () => {
    return !props.hovered && !props.mutedJustJoined && props.playing
      ? " rp-ui__controls-bottom-slider--visible"
      : "";
  };

  return (
    <>
      <div
        className={`rp-ui__controls ${playingClass()} ${controlsVisibleClass()}`}
        ref={controlsRef}
      >
        <div className="rp-ui__controls-inner">
          <div className="rp-ui__controls-topbar">
            <h1 className="rp-ui__controls-title">{props.title}</h1>
            {/* mobile chat display button */}
          </div>
          <div className="rp-ui__controls-buttons">
            {!props.playing && !props.muted && (
              <button
                className="rp-ui__controls-btn rp-ui__controls-play-btn"
                onClick={togglePlayback}
              >
                <PlayIcon />
              </button>
            )}
            {props.mutedJustJoined && (
              <button
                className="rp-ui__controls-btn rp-ui__controls-play-btn"
                onClick={() => {
                  props.setMuted(false);
                  props.setMutedJustJoined(false);
                }}
              >
                <MutedIcon />
                <span>Kliknij, aby anulować wyciszenie</span>
              </button>
            )}
          </div>
          <div className="rp-ui__controls-bottom">
            <button className="rp-ui__controls-btn" onClick={togglePlayback}>
              {props.playing ? <PauseIcon /> : <PlayIcon />}
            </button>
            <div className="rp-ui__duration-slider">
              <span className="rp-ui__duration-label">
                {formatTime(props.playedSeconds)}
              </span>
              <div className="rp-ui__seek-slider" ref={sliderRef}>
                <input
                  className="rp-ui__seek-slider__track"
                  type="range"
                  value={props.playedSeconds}
                  min="0"
                  max={props.duration}
                  step={0.01}
                  autoComplete="off"
                  onChange={seek}
                  readOnly={!isMod}
                />
                <progress
                  className="rp-ui__seek-slider__buffer"
                  max={props.duration}
                  value={props.loadedSeconds}
                  aria-hidden={true}
                ></progress>
              </div>
              <span className="rp-ui__duration-label">
                {formatTime(props.duration)}
              </span>
            </div>
            <div className="rp-ui__controls-bottom-options">
              <div className="rp-ui__controls-volume-wrapper">
                <button
                  className="rp-ui__controls-btn"
                  onClick={() => props.setMuted(!props.muted)}
                >
                  {props.muted ? <MutedIcon /> : <UnmutedIcon />}
                </button>
                <div className="rp-ui__controls-volume">
                  <input
                    type="range"
                    className="rp-ui__seek-slider__track"
                    min="0"
                    max="1"
                    step="0.01"
                    value={props.volume}
                    autoComplete="off"
                    onChange={changeVolume}
                    ref={volumeRef}
                  />
                </div>
              </div>
              {/* <div className="rp-ui__controls-options"></div> */}
              <button
                className="rp-ui__controls-btn"
                onClick={toggleFullScreen}
              >
                {isFullScreen ? <ExitFullScreenIcon /> : <FullScreenIcon />}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`rp-ui__controls-bottom-slider ${sliderVisibleClass()}`}>
        <div className="rp-ui__duration-slider rp-ui__duration-slider--disabled">
          <div className="rp-ui__seek-slider" ref={bottomSliderRef}>
            <input
              type="range"
              className="rp-ui__seek-slider__track"
              min="0"
              max={props.duration}
              step="0.01"
              value={props.playedSeconds}
              autoComplete="off"
              readOnly={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
