let delay: number = 0;

export const getDelay = () => {
  return delay;
};

// export const setDelay = (value: number) => {
//   delay = value;
// };

const isDelay = () => {
  return delay !== 0;
};

export const toggleDelay = () => {
  isDelay() ? (delay = 0) : (delay = 3);
};
