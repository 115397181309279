import Player from "./components/Player/Player";
import Chat from "./components/Chat/Chat";
import "./App.css";
import { useContext, useEffect, useRef, useState } from "react";
import { Rnd } from "react-rnd";
import React from "react";
import { DragHandleIcon } from "./components/Player/Svg";
import { io, Socket } from "socket.io-client";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

export const cn = (...args: any[]) => args.filter(Boolean).join(" ");

interface VisibilityContextValue {
  showChat: boolean;
  setShowChat: (show: boolean) => void;
  showStreamOverlay: boolean;
  setShowStreamOverlay: (show: boolean) => void;
}

const VisibilityContext = React.createContext<VisibilityContextValue>({
  showChat: true,
  setShowChat: () => {},
  showStreamOverlay: true,
  setShowStreamOverlay: () => {},
});

interface SocketContextInterface {
  socket: Socket | null;
  connectSocket: (token?: string) => void;
  disconnectSocket: () => void;
}

const SocketContext = React.createContext<SocketContextInterface | null>(null);

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const isSocketRef = useRef(false);

  const connectSocket = () => {
    if (isSocketRef.current) return;
    isSocketRef.current = true;
    const storedToken = localStorage.getItem("token");
    const socketUrl = process.env.REACT_APP_API_URL || "";
    let newSocket: Socket;
    if (storedToken) {
      newSocket = io(socketUrl, {
        extraHeaders: { Authorization: `Bearer ${storedToken}` },
      });
    } else {
      newSocket = io(socketUrl);
    }

    newSocket.on("connect", () => {
      console.log("Connected");
      isSocketRef.current = true;
    });

    newSocket.on("disconnect", () => {
      console.log("Disconnected");
      isSocketRef.current = false;
    });

    setSocket(newSocket);
  };

  const disconnectSocket = () => {
    if (socket) {
      socket.disconnect();
      setSocket(null);
    }
  };

  useEffect(() => {
    connectSocket();
  }, []);

  return (
    <SocketContext.Provider value={{ socket, connectSocket, disconnectSocket }}>
      {children}
    </SocketContext.Provider>
  );
};

function App() {
  const [showStreamOverlay, setShowStreamOverlay] = useState(true);
  const [showChat, setShowChat] = useState(true);

  return (
    <VisibilityContext.Provider
      value={{ showChat, setShowChat, showStreamOverlay, setShowStreamOverlay }}
    >
      <div className="App">
        <div className="stream">{showStreamOverlay && <StreamOverlay />}</div>
        <div className="main">
          <PanelGroup direction="horizontal" autoSaveId="layout">
            <Panel defaultSize={85} minSize={70}>
              <div className="player">
                <SocketProvider>
                  <Player />
                </SocketProvider>
              </div>
            </Panel>
            {showChat && (
              <>
                <PanelResizeHandle
                  className="drag-bar"
                  hitAreaMargins={{ coarse: 15, fine: 15 }}
                />
                <Panel minSize={10}>
                  <aside id="chat" className="chat">
                    <Chat channelName="nezzula" />
                  </aside>
                </Panel>
              </>
            )}
          </PanelGroup>
        </div>
      </div>
    </VisibilityContext.Provider>
  );
}

const StreamOverlay = () => {
  const [playerSize, setPlayerSize] = useState({ width: 400, height: 225 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const rndRef = useRef(null);

  const handleResize = (
    _e: any,
    _direction: any,
    ref: { offsetWidth: any; offsetHeight: any },
    _delta: any,
    _position: any
  ) => {
    setPlayerSize({
      width: ref.offsetWidth,
      height: ref.offsetHeight,
    });
    localStorage.setItem("camera-width", ref.offsetWidth);
    localStorage.setItem("camera-height", ref.offsetHeight);
    localStorage.setItem("camera-x", JSON.stringify(position.x));
    localStorage.setItem("camera-y", JSON.stringify(position.y));
  };

  const handleDragStop = (_e: any, d: any) => {
    setPosition(d);
    localStorage.setItem("camera-x", JSON.stringify(d.x));
    localStorage.setItem("camera-y", JSON.stringify(d.y));
  };

  useEffect(() => {
    const cameraWidth = localStorage.getItem("camera-width");
    if (cameraWidth) {
      setPlayerSize((prevSize) => ({
        ...prevSize,
        width: +cameraWidth,
      }));
    }
    const cameraHeight = localStorage.getItem("camera-height");
    if (cameraHeight) {
      setPlayerSize((prevSize) => ({
        ...prevSize,
        height: +cameraHeight,
      }));
    }

    const cameraX = localStorage.getItem("camera-x");
    if (cameraX) {
      setPosition((prevSize) => ({
        ...prevSize,
        x: +cameraX,
      }));
    }
    const cameraY = localStorage.getItem("camera-y");
    if (cameraY) {
      setPosition((prevSize) => ({
        ...prevSize,
        y: +cameraY,
      }));
    }
  }, []);

  return (
    <Rnd
      ref={rndRef}
      default={{
        x: 0,
        y: 0,
        width: playerSize.width,
        height: playerSize.height,
      }}
      className="rnd-box border"
      enableResizing={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: true,
        topLeft: true,
        bottomRight: true,
        bottomLeft: true,
      }}
      size={playerSize}
      position={position}
      onResize={handleResize}
      onDragStop={handleDragStop}
      dragHandleClassName="drag-handle"
      bounds="body"
      lockAspectRatio
    >
      <div className="stream-container">
        <div className="drag-handle border">
          <DragHandleIcon />
        </div>
        <iframe
          className=""
          src={`https://player.twitch.tv/?channel=nezzula&parent=${process.env.REACT_APP_ORIGIN_URL}`}
          height={playerSize.height}
          width={playerSize.width}
          title="stream"
          // allowFullScreen
        ></iframe>
      </div>
    </Rnd>
  );
};

export default App;
export { VisibilityContext };
export { SocketContext };
export const useSocket = () => {
  const context = useContext(SocketContext);

  return context?.socket;
};
